import React, { useEffect } from "react";
import Carousel from "react-material-ui-carousel";

import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  fetchEventData,
  incrementRequestCount,
  resetRequestCount,
  selectEventRequestCount,
  selectEvents,
} from "./eventsSlice";
import { EventCard } from "./EventCard";

export const EventCarousel = () => {
  const data = useAppSelector(selectEvents);
  const requestCount = useAppSelector(selectEventRequestCount);
  const dispatch = useAppDispatch();

  // On mount get the faq data
  useEffect(() => {
    if (requestCount === 0) {
      dispatch(fetchEventData());
      dispatch(incrementRequestCount());
    }
  });

  useEffect(() => {
    dispatch(resetRequestCount());
    // eslint-disable-next-line
  }, []);

  if (data.length === 0) {
    return null;
  }
  return (
    <Carousel navButtonsAlwaysInvisible={true} indicators={false}>
      {data.map((item, index) => (
        <EventCard key={index} event={item} />
      ))}
    </Carousel>
  );
};
