import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchFileDownloads, FileDownload } from "./fileAPI";

export interface FileState {
  data: FileDownload[];
  requestCount: number;
}

const initialState: FileState = {
  data: [],
  requestCount: 0,
};

export const fetchFileData = createAsyncThunk("files/fetch", async () => {
  const url = await fetchFileDownloads();
  return url;
});

export const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    incrementRequestCount: (state) => {
      state.requestCount += 1;
    },
    resetRequestCount: (state) => {
      state.requestCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileData.pending, (state) => {
        state.data = [];
      })
      .addCase(fetchFileData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchFileData.rejected, (state) => {
        state.data = [];
      });
  },
});

export const selectFiles = (state: RootState) => state.files.data;
export const selectGetAllFilesRequestCount = (state: RootState) =>
  state.files.requestCount;

export const { incrementRequestCount, resetRequestCount } = fileSlice.actions;

export default fileSlice.reducer;
