import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchFaqs, FAQ } from "./faqsAPI";

export interface FaqState {
  data: FAQ[];
  requestCount: number;
}

const initialState: FaqState = {
  data: [],
  requestCount: 0,
};

export const fetchFaqData = createAsyncThunk("faq/fetch", async () => {
  const url = await fetchFaqs();
  return url;
});

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    incrementRequestCount: (state) => {
      state.requestCount += 1;
    },
    resetRequestCount: (state) => {
      state.requestCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaqData.pending, (state) => {
        state.data = [];
      })
      .addCase(fetchFaqData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchFaqData.rejected, (state) => {
        state.data = [];
      });
  },
});

export const selectFaqs = (state: RootState) => state.faqs.data;
export const selectFaqRequestCount = (state: RootState) =>
  state.faqs.requestCount;

export const { incrementRequestCount, resetRequestCount } = faqSlice.actions;

export default faqSlice.reducer;
