import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchHero } from "./heroAPI";

export interface HeroState {
  url: string;
}

const initialState: HeroState = {
  url: "",
};

export const fetchHeroImage = createAsyncThunk("hero/fetch", async () => {
  const url = await fetchHero();
  return url;
});

export const heroSlice = createSlice({
  name: "hero",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeroImage.pending, (state) => {
        state.url = "";
      })
      .addCase(fetchHeroImage.fulfilled, (state, action) => {
        state.url = action.payload;
      })
      .addCase(fetchHeroImage.rejected, (state) => {
        state.url = "";
      });
  },
});

export const selectHero = (state: RootState) => state.hero.url;

export default heroSlice.reducer;
