import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchLogo } from "./logoAPI";

export interface LogoState {
  url: string;
}

const initialState: LogoState = {
  url: "",
};

export const fetchLogoImage = createAsyncThunk("logo/fetch", async () => {
  const url = await fetchLogo();
  return url;
});

export const logoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogoImage.pending, (state) => {
        state.url = "";
      })
      .addCase(fetchLogoImage.fulfilled, (state, action) => {
        state.url = action.payload;
      })
      .addCase(fetchLogoImage.rejected, (state) => {
        state.url = "";
      });
  },
});

export const selectLogo = (state: RootState) => state.logo.url;

export default logoSlice.reducer;
