import React from "react";
import Hero from "features/hero/Hero";
import TopBar from "features/topBar/TopBar";
import Introduction from "features/introduction/Introduction";
import Faqs from "features/faqs/Faqs";
import Spacer from "features/spacer/Spacer";
import GallerySection from "features/gallery/Gallery";
import EnquirySection from "features/enquirySection/EnquirySection";
import EventSection from "features/events/EventsSection";

const Home = () => {
  return (
    <React.Fragment>
      <TopBar />
      <Hero />
      <Spacer background="white" />
      <Introduction background="white" />
      <Spacer background="white" />
      <Spacer background="primary" />
      <EventSection background="primary" />
      <GallerySection background="primary" />
      <Spacer background="primary" />
      <Spacer />
      <Faqs />
      <Spacer />
      <Spacer background="primary" />
      <EnquirySection background="primary" />
      <Spacer background="primary" />
    </React.Fragment>
  );
};

export default Home;
