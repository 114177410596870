import React from "react";
import { Container, Grid } from "@mui/material";

import { BackgroundProp } from "app/types";
import Section from "features/section/Section";
import EnquiryForm from "features/enquiryForm/EnquiryForm";
import DonationForm from "features/donationForm/DonationForm";

const EnquirySection = ({ background }: BackgroundProp) => {
  return (
    <Section background={background}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <EnquiryForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <DonationForm />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default EnquirySection;
