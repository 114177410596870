import React from "react";
import { Box, CardMedia } from "@mui/material";
import FileDescription from "@mui/icons-material/Description";

import { FileDownload } from "features/fileDownload/fileAPI";

interface DownloadMediaProps {
  download: FileDownload;
}

const DownloadMedia = ({ download }: DownloadMediaProps) => {
  const { REACT_APP_CMS_HOST } = process.env;

  if (download.Thumbnail) {
    return (
      <CardMedia
        component="img"
        sx={{ width: 70, height: 75 }}
        image={`${REACT_APP_CMS_HOST}${download.Thumbnail.path}`}
        alt={`${download.Description} thumbnail image`}
      />
    );
  }

  return (
    <Box
      sx={{
        width: 70,
        height: 75,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FileDescription sx={{ width: 50, height: 50 }} />
    </Box>
  );
};

export default DownloadMedia;
