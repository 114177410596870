import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { Event } from "./eventsAPI";

interface EventCardProps {
  event: Event;
}

export const EventCard = ({ event }: EventCardProps) => {
  return (
    <Card>
      <CardHeader
        title={event.name}
        subheader={`${event.date} at ${event.time}`}
      />
      <CardContent>
        <span dangerouslySetInnerHTML={{ __html: event.details }}></span>
      </CardContent>
      <CardContent>
        To book your place contact:
        <Typography>{event.bookingContact.name}</Typography>
        {event.bookingContact.email && (
          <Typography>
            <a href={`mailto:${event.bookingContact.email}`}>
              {event.bookingContact.email}
            </a>
          </Typography>
        )}
        {event.bookingContact.phone && (
          <Typography>
            <a href={`tel:${event.bookingContact.phone}`}>
              {event.bookingContact.phone}
            </a>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
