import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchIntro } from "./introductionAPI";

export interface IntroState {
  html: string;
}

const initialState: IntroState = {
  html: "",
};

export const fetchIntroduction = createAsyncThunk("intro/fetch", async () => {
  const data = await fetchIntro();
  return data;
});

export const introSlice = createSlice({
  name: "intro",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntroduction.pending, (state) => {
        state.html = "";
      })
      .addCase(fetchIntroduction.fulfilled, (state, action) => {
        state.html = action.payload;
      })
      .addCase(fetchIntroduction.rejected, (state) => {
        state.html = "";
      });
  },
});

export const selectIntro = (state: RootState) => state.intro.html;

export default introSlice.reducer;
