import React from "react";
import { Container, Typography, useTheme } from "@mui/material";

import { BackgroundProp } from "app/types";
import Section from "features/section/Section";
import { EventCarousel } from "features/events/EventCarousel";

const EventsSection = ({ background }: BackgroundProp) => {
  const theme = useTheme();

  return (
    <Section background={background}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h4"
          align="center"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          Upcoming Events
        </Typography>
        <EventCarousel />
      </Container>
    </Section>
  );
};

export default EventsSection;
