export interface Home {
  Introduction: string;
  Hero: {
    path: string;
  };
  Logo: {
    path: string;
  };
}

export async function fetchHero() {
  const cmsAPI = process.env.REACT_APP_CMS_ENDPOINT;

  const response = await fetch(`${cmsAPI}/content/item/Home`);

  const data: Home = await response.json();

  return data?.Hero?.path;
}
