import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTheme } from "@mui/material";

interface SectionProps {
  style?: React.CSSProperties;
  background?: "primary" | "secondary" | "white";
}

const Section = ({
  children,
  background,
  style,
}: PropsWithChildren<SectionProps>) => {
  const theme = useTheme();
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    if (background) {
      switch (background) {
        case "primary": {
          setBackgroundColor(theme.palette.primary.light);
          break;
        }
        case "secondary": {
          setBackgroundColor(theme.palette.secondary.light);
          break;
        }
        case "white": {
          setBackgroundColor(theme.palette.common.white);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [theme, background, setBackgroundColor]);

  return <section style={{ ...style, backgroundColor }}>{children}</section>;
};

export default Section;
