import React, { useEffect, useState } from "react";
import { Container, Typography, useTheme } from "@mui/material";
import { Gallery } from "react-grid-gallery";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";

import { BackgroundProp } from "app/types";
import Section from "features/section/Section";
import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  fetchGalleryData,
  incrementRequestCount,
  resetRequestCount,
  selectGallery,
  selectGalleryRequestCount,
} from "./gallerySlice";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

interface GalleryProps extends BackgroundProp {}

const GallerySection = ({ background }: GalleryProps) => {
  const theme = useTheme();
  const items = useAppSelector(selectGallery);
  const requestCount = useAppSelector(selectGalleryRequestCount);
  const [index, setIndex] = useState(-1);

  const dispatch = useAppDispatch();

  // Get the items
  useEffect(() => {
    if (requestCount === 0) {
      dispatch(fetchGalleryData());
      dispatch(incrementRequestCount());
    }
  });

  useEffect(() => {
    dispatch(resetRequestCount());
    // eslint-disable-next-line
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <Section background={background}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h4"
          align="center"
          color={theme.palette.primary.contrastText}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          Gallery
        </Typography>
        <Gallery
          images={items}
          onClick={(index, image) => setIndex(index)}
          enableImageSelection={false}
          maxRows={3}
        />
        <Lightbox
          slides={items}
          plugins={[Captions]}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </Container>
    </Section>
  );
};

export default GallerySection;
