import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchGallery, Image } from "./galleryAPI";

interface GalleryState {
  items: Image[];
  requestCount: number;
}

const initialState: GalleryState = {
  items: [],
  requestCount: 0,
};

export const fetchGalleryData = createAsyncThunk("gallery/fetch", async () => {
  const url = await fetchGallery();
  return url;
});

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    incrementRequestCount: (state) => {
      state.requestCount += 1;
    },
    resetRequestCount: (state) => {
      state.requestCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryData.pending, (state) => {
        state.items = [];
      })
      .addCase(fetchGalleryData.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(fetchGalleryData.rejected, (state) => {
        state.items = [];
      });
  },
});

export const selectGallery = (state: RootState) => state.gallery.items;
export const selectGalleryRequestCount = (state: RootState) =>
  state.gallery.requestCount;

export const { incrementRequestCount, resetRequestCount } =
  gallerySlice.actions;

export default gallerySlice.reducer;
