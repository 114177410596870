interface ApiImage {
  path: string;
  width: number;
  height: number;
}

export interface Image {
  src: string;
  width: number;
  height: number;
  description: string;
}

interface GalleryApiItem {
  Image: ApiImage;
  Caption: string;
}

export async function fetchGallery() {
  const cmsUrl = process.env.REACT_APP_CMS_HOST;
  const cmsAPI = process.env.REACT_APP_CMS_ENDPOINT;

  const response = await fetch(`${cmsAPI}/content/items/Gallery`);

  const data: GalleryApiItem[] = await response.json();

  const returnData: Image[] = data.map((item) => ({
    src: `${cmsUrl}${item.Image.path}`,
    height: item.Image.height,
    width: item.Image.width,
    description: item.Caption,
  }));

  return returnData;
}
