import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";

import { BackgroundProp } from "app/types";

interface SpacerProps extends BackgroundProp {}

const Spacer = ({ background }: SpacerProps) => {
  const theme = useTheme();
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    if (background) {
      switch (background) {
        case "primary": {
          setBackgroundColor(theme.palette.primary.light);
          break;
        }
        case "secondary": {
          setBackgroundColor(theme.palette.secondary.light);
          break;
        }
        case "white": {
          setBackgroundColor(theme.palette.common.white);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [theme, background, setBackgroundColor]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "5vh",
        backgroundColor,
      }}
    />
  );
};

export default Spacer;
