import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FaqProps {
  title: string;
  content: string;
}

const Faq = ({ title, content }: FaqProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="h6">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1">{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Faq;
