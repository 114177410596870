import React from "react";
import { Box } from "@mui/material";
import { Routes, Route, Outlet } from "react-router-dom";

import Home from "pages/Home";
import Documents from "pages/Documents";
import Events from "pages/Events";
import NoPath from "pages/NoPath";
import TopBar from "features/topBar/TopBar";
import BottomBar from "features/bottomBar/BottomBar";

const Layout = () => {
  return (
    <React.Fragment>
      <TopBar />
      <Box
        sx={{
          mt: { xs: "60px", md: "64px" },
          width: "100%",
          minHeight: { xs: "calc(100vh - 116px)", md: "calc(100vh - 128px)" },
        }}
      >
        <Outlet />
      </Box>
      <BottomBar />
    </React.Fragment>
  );
};

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/events" element={<Events />} />
          <Route path="*" element={<NoPath />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
