export interface File {
  path: string;
  title: string;
  mime: string;
  _hash: string;
}

export interface Thumbnail {
  path: string;
}

export interface FileDownload {
  Document: File;
  Description: string;
  Thumbnail: Thumbnail;
}

export async function fetchFileDownloads() {
  const { REACT_APP_CMS_ENDPOINT } = process.env;

  const response = await fetch(
    `${REACT_APP_CMS_ENDPOINT}/content/items/Documents`
  );

  const data: FileDownload[] = await response.json();

  return data;
}
