import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  useTheme,
  Button,
} from "@mui/material";

const DonationForm = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const theme = useTheme();

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch {
      setCopyError(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCopyError(false);
      setCopySuccess(false);
    }, 1500);
  }, [copySuccess, copyError]);

  return (
    <Card
      id="donate"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <CardHeader
        title="Make a donation"
        subheader="If you would like to make a donation please use the bank details shown here"
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" sx={{ paddingBottom: theme.spacing(2) }}>
          All donations are greatly received and we would encourage you to setup
          a regular donation if you are able to. You can copy the items below by
          clicking on them
        </Typography>
        <Button
          sx={{ marginBottom: theme.spacing(2) }}
          onClick={() =>
            copyToClipboard("Provincial Grand Lodge Devonshire PBF")
          }
        >
          Account Name: Provincial Grand Lodge Devonshire PBF
        </Button>
        <Button
          sx={{ marginBottom: theme.spacing(2) }}
          onClick={() => copyToClipboard("82-12-08")}
        >
          Sort code: 82-12-08
        </Button>
        <Button
          sx={{ marginBottom: theme.spacing(2) }}
          onClick={() => copyToClipboard("00048636")}
        >
          Account Number: 00048636
        </Button>
        {copySuccess && (
          <Typography
            variant="body1"
            sx={{
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText,
              width: "100%",
              paddingTop: theme.spacing(),
              paddingBottom: theme.spacing(),
              textAlign: "center",
            }}
          >
            Copied to clipboard
          </Typography>
        )}
        {copyError && (
          <Typography
            variant="body1"
            sx={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              width: "100%",
              paddingTop: theme.spacing(),
              paddingBottom: theme.spacing(),
              textAlign: "center",
            }}
          >
            Unable to copy to clipboard
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DonationForm;
