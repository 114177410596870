import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchEvents, Event } from "./eventsAPI";

export interface EventState {
  data: Event[];
  requestCount: number;
}

const initialState: EventState = {
  data: [],
  requestCount: 0,
};

export const fetchEventData = createAsyncThunk("event/fetch", async () => {
  const url = await fetchEvents();
  return url;
});

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    incrementRequestCount: (state) => {
      state.requestCount += 1;
    },
    resetRequestCount: (state) => {
      state.requestCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventData.pending, (state) => {
        state.data = [];
      })
      .addCase(fetchEventData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchEventData.rejected, (state) => {
        state.data = [];
      });
  },
});

export const selectEvents = (state: RootState) => state.events.data;
export const selectEventRequestCount = (state: RootState) =>
  state.events.requestCount;

export const { incrementRequestCount, resetRequestCount } = eventSlice.actions;

export default eventSlice.reducer;
