import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import { fetchLogoImage, selectLogo } from "./logoSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

const Logo = () => {
  const { REACT_APP_CMS_HOST } = process.env;
  const theme = useTheme();
  const path = useAppSelector(selectLogo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (path === "") {
      dispatch(fetchLogoImage());
    }
  });

  if (path.length === 0) {
    return null;
  }

  return (
    <img
      src={`${REACT_APP_CMS_HOST}${path}`}
      alt="logo"
      style={{ marginRight: theme.spacing(2) }}
    />
  );
};

export default Logo;
