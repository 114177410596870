import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
} from "@mui/material";
import emailJs from "@emailjs/browser";

import SuccessMessage from "./SuccessMessage";

const EnquiryForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendSuccess, setSendSuccess] = useState(false);

  const sendForm = () => {
    emailJs
      .send(
        "service_gn9xtx7",
        "template_iou8zri",
        { name, email, message },
        "JK1QCo-UexnOyK7V8"
      )
      .then(
        function (response) {
          setName("");
          setEmail("");
          setMessage("");
          setSendSuccess(true);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const resetSuccessMessage = () => {
    setTimeout(() => {
      setSendSuccess(false);
    }, 4000);
  };

  useEffect(() => {
    if (sendSuccess) {
      resetSuccessMessage();
    }
  }, [sendSuccess]);

  return (
    <form id="contact">
      <Card>
        <CardHeader
          title="Contact the committee"
          subheader="If you have a question for the committee please use this form to
                  get in touch"
        />
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            required
            label="Name"
            variant="filled"
            value={name}
            onChange={(event) => setName(event.target.value)}
            margin="normal"
          />
          <TextField
            required
            label="Email"
            variant="filled"
            value={email}
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            margin="normal"
          />
          <TextField
            required
            label="Message"
            variant="filled"
            value={message}
            autoComplete="off"
            margin="normal"
            multiline={true}
            rows={6}
            onChange={(event) => setMessage(event.target.value)}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <SuccessMessage show={sendSuccess} />
          <Button
            onClick={sendForm}
            color="primary"
            variant="contained"
            size="large"
          >
            Send
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default EnquiryForm;
