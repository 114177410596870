import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const menuItems = [
  {
    title: "Home",
    route: "/",
  },
  {
    title: "Documents",
    route: "/documents",
  },
  {
    title: "Events",
    route: "/events",
  },
  {
    title: "Contact Us",
    route: "/#contact",
  },
  {
    title: "Make a  donation",
    route: "/#donate",
  },
];

const NavMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const value = hash.split("#");
      handleScroll(value[1]);
    } else if (location.pathname === "/") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (route: string) => {
    navigate(route);
  };

  const handleScroll = (id: string) => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView();
    }, 300);
  };

  return (
    <nav>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <IconButton
          size="large"
          onClick={handleOpenMenu}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {menuItems.map((item, index) => {
            return (
              <MenuItem
                key={`nav-${index}`}
                onClick={() => {
                  handleClick(item.route);
                  handleCloseMenu();
                }}
              >
                <Typography variant="button">{item.title}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-around",
          display: { xs: "none", md: "flex" },
        }}
      >
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={`nav-${index}`}
              onClick={() => handleClick(item.route)}
            >
              <Typography variant="button">{item.title}</Typography>
            </MenuItem>
          );
        })}
      </Box>
    </nav>
  );
};

export default NavMenu;
