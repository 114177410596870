import React from "react";
import { AppBar, Container, Toolbar, useTheme } from "@mui/material";
import NavMenu from "features/navMenu/NavMenu";

import Logo from "features/logo/Logo";

const TopBar = () => {
  const theme = useTheme();

  return (
    <AppBar sx={{ boxShadow: "none" }}>
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            paddingTop: theme.spacing(),
            paddingBottom: theme.spacing(),
            justifyContent: { xs: "space-between", md: "flex-start" },
          }}
        >
          <Logo />
          <NavMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
