import React from "react";
import { AppBar, Toolbar } from "@mui/material";

const BottomBar = () => {
  return (
    <AppBar
      position="relative"
      sx={{ top: "auto", bottom: 0, backgroundColor: "#333333" }}
    >
      <Toolbar />
    </AppBar>
  );
};

export default BottomBar;
