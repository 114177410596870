import React, { useEffect } from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";

import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  fetchEventData,
  incrementRequestCount,
  resetRequestCount,
  selectEventRequestCount,
  selectEvents,
} from "features/events/eventsSlice";
import { EventCard } from "features/events/EventCard";

const Events = () => {
  const data = useAppSelector(selectEvents);
  const requestCount = useAppSelector(selectEventRequestCount);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  // On mount get the faq data
  useEffect(() => {
    if (requestCount === 0) {
      dispatch(fetchEventData());
      dispatch(incrementRequestCount());
    }
  });

  useEffect(() => {
    dispatch(resetRequestCount());
    // eslint-disable-next-line
  }, []);

  if (data.length === 0) {
    return <Typography>No events to show at this time</Typography>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ height: "100%", paddingY: theme.spacing(2) }}
    >
      <Grid container spacing={1}>
        {data.map((item, index) => (
          <Grid item key={index} md={6} sm={12}>
            <EventCard key={index} event={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Events;
