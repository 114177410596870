import "@fontsource/montserrat";
import { ThemeOptions } from "@mui/material";

const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#007FE0",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#d62828",
      light: "#ff6152",
      dark: "#9c0000",
      contrastText: "#ffffff",
    },
    success: {
      main: "#a6f1a6",
      contrastText: "#333",
    },
    error: {
      main: "#E55451",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "montserrat",
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background-color: #E0E0E0;
        },
        html {
          scroll-behavior: smooth;
        }
      `,
    },
  },
};

export default baseTheme;
