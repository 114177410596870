import React, { useEffect } from "react";
import { Container, Typography, useTheme } from "@mui/material";
import Section from "features/section/Section";

import { BackgroundProp } from "app/types";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { fetchIntroduction, selectIntro } from "./introductionSlice";

interface IntroProps extends BackgroundProp {}

const Introduction = ({ background }: IntroProps) => {
  const theme = useTheme();
  const introduction = useAppSelector(selectIntro);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (introduction === "") {
      dispatch(fetchIntroduction());
    }
  });

  return (
    <Section
      background={background}
      style={{ paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: introduction }}
        />
      </Container>
    </Section>
  );
};

export default Introduction;
