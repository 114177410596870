import React from "react";
import { Container } from "@mui/material";

import FileDownload from "features/fileDownload/FileDownload";

const Documents = () => {
  return (
    <Container maxWidth="lg" sx={{ height: "100%" }}>
      <FileDownload />
    </Container>
  );
};

export default Documents;
