import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Section from "features/section/Section";

import { useAppSelector, useAppDispatch } from "app/hooks";
import { fetchHeroImage, selectHero } from "./heroSlice";

const Hero = () => {
  const { REACT_APP_CMS_HOST } = process.env;
  const theme = useTheme();
  const path = useAppSelector(selectHero);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (path === "") {
      dispatch(fetchHeroImage());
    }
  });

  if (path.length === 0) {
    return null;
  }

  return (
    <Section style={{ height: "500px" }}>
      <Box
        sx={{
          height: "500px",
          backgroundImage: `url(${REACT_APP_CMS_HOST}${path})`,
          backgroundSize: "cover",
          backgroundPosition: { xs: "75%", md: "-50%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          color={theme.palette.primary.contrastText}
          sx={{ textAlign: { xs: "center" } }}
        >
          Plymouth Building Fund
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          color={theme.palette.primary.contrastText}
          sx={{ textAlign: { xs: "center" } }}
        >
          Securing the future of Freemasonry in Plymouth
        </Typography>
      </Box>
    </Section>
  );
};

export default Hero;
