import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

import DownloadCard from "features/fileDownloadCard/DownloadCard";
import {
  fetchFileData,
  incrementRequestCount,
  resetRequestCount,
  selectGetAllFilesRequestCount,
  selectFiles,
} from "./fileSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

const FileDownload = () => {
  const data = useAppSelector(selectFiles);
  const requestCount = useAppSelector(selectGetAllFilesRequestCount);
  const dispatch = useAppDispatch();

  // On mount get file list
  useEffect(() => {
    if (requestCount === 0) {
      dispatch(fetchFileData());
      dispatch(incrementRequestCount());
    }
  });

  useEffect(() => {
    dispatch(resetRequestCount());
    // eslint-disable-next-line
  }, []);

  if (data.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">No files available for download</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      {data.map((item) => (
        <DownloadCard key={item.Document._hash} download={item} />
      ))}
    </Box>
  );
};

export default FileDownload;
