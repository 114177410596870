import React, { useEffect } from "react";
import { Container, useTheme } from "@mui/material";
import Faq from "./Faq";
import Section from "features/section/Section";

import { useAppSelector, useAppDispatch } from "app/hooks";
import {
  fetchFaqData,
  incrementRequestCount,
  resetRequestCount,
  selectFaqRequestCount,
  selectFaqs,
} from "./faqSlice";
import { Typography } from "@mui/material";

const Faqs = () => {
  const data = useAppSelector(selectFaqs);
  const requestCount = useAppSelector(selectFaqRequestCount);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  // On mount get the faq data
  useEffect(() => {
    if (requestCount === 0) {
      dispatch(fetchFaqData());
      dispatch(incrementRequestCount());
    }
  });

  useEffect(() => {
    dispatch(resetRequestCount());
    // eslint-disable-next-line
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <Section>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h4"
          align="center"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          Frequently Asked Questions
        </Typography>
        {data.map((faq, index) => (
          <Faq key={`faq-${index}`} title={faq.Question} content={faq.Answer} />
        ))}
      </Container>
    </Section>
  );
};

export default Faqs;
