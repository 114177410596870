import { Home } from "features/hero/heroAPI";

export async function fetchLogo() {
  const cmsAPI = process.env.REACT_APP_CMS_ENDPOINT;

  const response = await fetch(`${cmsAPI}/content/item/Home`);

  const data: Home = await response.json();

  return data?.Logo?.path;
}
