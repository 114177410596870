import React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  useTheme,
  Typography,
} from "@mui/material";
import { FileDownload } from "features/fileDownload/fileAPI";
import DownloadMedia from "./DownloadMedia";

interface DownloadCardProps {
  download: FileDownload;
}

const DownloadCard = ({ download }: DownloadCardProps) => {
  const { REACT_APP_CMS_HOST } = process.env;
  const theme = useTheme();

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = download.Document.title;
    link.href = `${REACT_APP_CMS_HOST}${download.Document.path}`;
    link.target = "_blank";
    link.click();
  };

  return (
    <Card
      sx={{
        margin: theme.spacing(2),
        padding: theme.spacing(),
        width: "275px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">{download.Document.title}</Typography>
          <Typography variant="body1">{download.Description}</Typography>
        </Box>
        <DownloadMedia download={download} />
      </Box>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button onClick={onDownload}>Download File</Button>
      </CardActions>
    </Card>
  );
};

export default DownloadCard;
