import { Home } from "features/hero/heroAPI";

export async function fetchIntro() {
  const { REACT_APP_CMS_ENDPOINT } = process.env;

  const response = await fetch(`${REACT_APP_CMS_ENDPOINT}/content/item/Home`);

  const data: Home = await response.json();

  return data?.Introduction;
}
