import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import heroReducer from "features/hero/heroSlice";
import introReducer from "features/introduction/introductionSlice";
import faqReducer from "features/faqs/faqSlice";
import fileReducer from "features/fileDownload/fileSlice";
import galleryReducer from "features/gallery/gallerySlice";
import logoReducer from "features/logo/logoSlice";
import eventReducer from "features/events/eventsSlice";

export const store = configureStore({
  reducer: {
    hero: heroReducer,
    intro: introReducer,
    faqs: faqReducer,
    gallery: galleryReducer,
    files: fileReducer,
    logo: logoReducer,
    events: eventReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
