export interface FAQ {
  Question: string;
  Answer: string;
}

export async function fetchFaqs() {
  const { REACT_APP_CMS_ENDPOINT } = process.env;

  const response = await fetch(`${REACT_APP_CMS_ENDPOINT}/content/items/FAQ`);

  const data: FAQ[] = await response.json();

  return data;
}
