import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

interface MessageProps {
  show: boolean;
}

const SuccessMessage = ({ show }: MessageProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
        flex: 1,
        padding: theme.spacing(),
        margin: theme.spacing(),
        borderRadius: theme.shape.borderRadius / 4,
        opacity: show ? 1 : 0,
        transition: "opacity 300ms",
      }}
    >
      <Typography variant="body1">
        Thank you for your enquiry. We will respond soon
      </Typography>
    </Box>
  );
};

export default SuccessMessage;
