interface BookingContact {
  name: string;
  email?: string;
  phone?: string;
}

export interface Event {
  name: string;
  date: string;
  time: string;
  location: string;
  details: string;
  bookingContact: BookingContact;
}

export async function fetchEvents() {
  const { REACT_APP_CMS_ENDPOINT } = process.env;

  const response = await fetch(
    `${REACT_APP_CMS_ENDPOINT}/content/items/Events`
  );

  const data: Event[] = await response.json();

  return data;
}
